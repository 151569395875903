import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function presalesreturnboundUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
      /*
     { key: 'id', label: 'ID', sortable: true },
    { key: 'returnbound_no', label: '编号 QZTH+22 01 01 12 12 12 1234 年+月+日+小时+分+秒+4位随机数', sortable: true },
    { key: 'type_id', label: '订单类型 1线下 2线上', sortable: true },
    { key: 'source', label: '订单来源   1手机 2PC', sortable: true },
    { key: 'channel_id', label: '渠道ID', sortable: true },
    { key: 'channel_name', label: '渠道', sortable: true },
    { key: 'company_id', label: '销售主体ID', sortable: true },
    { key: 'company_name', label: '销售主体名称', sortable: true },
    { key: 'warehouse_id', label: '入库仓仓库ID', sortable: true },
    { key: 'warehouse_name', label: '入库仓仓库名称', sortable: true },
    { key: 'store_id', label: '店铺ID', sortable: true },
    { key: 'store_name', label: '店铺名称', sortable: true },
    { key: 'reason', label: '退货原因 sales_returnbound_reasontype', sortable: true },
    { key: 'subtotal', label: '小计金额', sortable: true },
    { key: 'item_count', label: '商品件数', sortable: true },
    { key: 'memo', label: '备注', sortable: true },
    { key: 'status', label: '销售前置单订单状态   pre_returnbound_status', sortable: true },
    { key: 'state', label: '状态', sortable: true },
    { key: 'add_time', label: '创建时间', sortable: true },
    { key: 'creator', label: '创建人', sortable: true },
    { key: 'modify_time', label: '修改时间', sortable: true },
    { key: 'updator', label: '修改人', sortable: true },
    { key: 'print_times', label: '打印次数', sortable: true },
    { key: 'return_time', label: '退货日期', sortable: true },
    { key: 'create_returnbound_id', label: '生成的退货单ID', sortable: true },
    { key: 'sales_id', label: '销售员ID', sortable: true },
     { key: 'sales_name', label: '销售人员', sortable: true },
     { key: 'trueqty_total', label: '总实际退货数量', sortable: true },
    { key: 'attachments', label: '附件', sortable: true },
    { key: 'remark', label: '备注', sortable: true },
    { key: 'is_inbound', label: '是否已入库', sortable: true },
      * */

    //{ key: 'id', label: 'ID', sortable: true },
    { key: 'select', label: '#'},
    { key: 'returnbound_no', label: '退货编号', sortable: true },
    { key: 'channel_name', label: '终端客户', sortable: true },
    { key: 'warehouse_name', label: '退货仓库', sortable: true },
    { key: 'item_count', label: '退货数量', sortable: true },
    { key: 'subtotal', label: '退货金额', sortable: true },
    { key: 'return_time', label: '退货日期', sortable: true },
    { key: 'sales_id', label: '销售人员', sortable: true },
    { key: 'updator', label: '修改人', sortable: true },
    { key: 'add_time', label: '创建时间', sortable: true },
    { key: 'creator', label: '创建人', sortable: true },
    { key: 'modify_time', label: '修改时间', sortable: true },
    { key: 'print_times', label: '打印次数', sortable: true },
    { key: 'store_name', label: '团队名称', sortable: true },
    { key: 'status', label: '单据状态', sortable: true },
    { key: 'attachments', label: '附件', sortable: true },
    { key: 'memo', label: '前置备注', sortable: true },
    //{ key: 'remark', label: '退货通知单备注', sortable: true },
    { key: 'create_returnbound_id', label: '退货通知单编号', sortable: true },
    { key: 'is_inbound', label: '是否已入库', sortable: true },
    { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const selected = ref(1)
  const orderBy = ref('returnbound_id')
  const isSortDirDesc = ref(true)
  const dataTitleTotal = ref(0)
  const preOrderStatus0 = ref(0)
  const preOrderStatus1 = ref(0)
  const preOrderStatus2 = ref(0)
  const preOrderStatus3 = ref(0)
  const preOrderStatus4 = ref(0)
  const preOrderStatus5 = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const dataTitle =  computed(() => {
    return {
      total:dataTitleTotal.value,
      preOrderStatus0:preOrderStatus0.value===undefined?0:preOrderStatus0.value,
      preOrderStatus1:preOrderStatus1.value===undefined?0:preOrderStatus1.value,
      preOrderStatus2:preOrderStatus2.value===undefined?0:preOrderStatus2.value,
      preOrderStatus3:preOrderStatus3.value===undefined?0:preOrderStatus3.value,
      preOrderStatus4:preOrderStatus4.value===undefined?0:preOrderStatus4.value,
      preOrderStatus5:preOrderStatus5.value===undefined?0:preOrderStatus5.value,
    }
  })

  const searchList = (ctx, callback) => {
    const condition = {...store.getters['presalesreturnbound/getCondition']}
    condition.status = selected.value
    const params = {
      search_key: searchKey.value,
      start: start.value,
      limit: limit.value,
      order_by: orderBy.value,
      order_desc: isSortDirDesc.value === true ? 'desc':'',
    }
    Object.assign(params,condition)
    const warehouseArray = []
    if (!isEmpty(condition.warehouseArray)){
      condition.warehouseArray.forEach((value, index, array) => {
        warehouseArray.push(value.value)
      })
    }
    params.warehouseIds = warehouseArray.join(",")
    store
      .dispatch('presalesreturnbound/search', params)
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        dataTitleTotal.value = data.ext.size
        preOrderStatus0.value = data.ext.preOrderStatus0
        preOrderStatus1.value = data.ext.preOrderStatus1
        preOrderStatus2.value = data.ext.preOrderStatus2
        preOrderStatus3.value = data.ext.preOrderStatus3
        preOrderStatus4.value = data.ext.preOrderStatus4
        preOrderStatus5.value = data.ext.preOrderStatus5
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    dataTitle,
    selected,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
