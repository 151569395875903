<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
            <b-alert
                style="margin-top: 5px"
                variant="primary"
                show
            >
              <div class="alert-body">
                <span><strong>已勾选:{{
                    checked.length
                  }}  合计退货数量:{{ checked.reduce((sum, e) => sum + Number(e.item_count || 0), 0) }}</strong> </span>
              </div>
            </b-alert>
          </b-col>
          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
                  class="mr-1"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
                  @click="toLODOP"
                  class="mr-1"
              >
                <feather-icon
                    icon="PrinterIcon"
                    class="mr-50"
                />
                <span class="align-middle">打印</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
                  @click="exportReturnData"
                  class="mr-1"
              >
                <feather-icon
                    icon="PrinterIcon"
                    class="mr-50"
                />
                <span class="align-middle">导出</span>
              </b-button>


              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="advanced_search_excel"
                  style="margin: 5px;"
                  v-if="readonlyExcel"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">导入</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
                  @click="distribution"
                  class="mr-1"
              >
                <feather-icon
                    icon="PrinterIcon"
                    class="mr-50"
                />
                <span class="align-middle">打印配货单</span>
              </b-button>

            </div>
          </b-col>
        </b-row>
        <b-form>
          <b-card
              v-show="advanced_search_modal_excel"
          >

            <b-row>
              <!--excel-->

              <b-col md="12">
                <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    label="相关附件"
                    label-for="attachments_ids"
                    label-size="sm"
                    class="mb-1"
                >

                  <attachment-upload :theme="'files'"
                                     :object_type="'excel'"
                                     @change="onUploaded"
                  />
                </b-form-group>
              </b-col>

              <!--导入关闭-->
              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="exportData"
                    v-if="showOr"
                >
                  <span class="align-right">导入</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search_excel"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>

          </b-card>
        </b-form>
        <!--查询条件 card-->
        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <!-- 查询条件     终端客户（点选）  团队名称（点选） 仓库名称（点选，默认当前人关联仓库）  -->
              <!--商品名称-->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品名称:"
                    label-for="productName"
                    label-size="sm"

                >
                  <b-form-input
                      v-model="condition.productCondition"
                      name="productName"
                      class="d-inline-block mr-1"
                      size="sm"
                      placeholder="商品名称或69码"
                  />
                </b-form-group>
              </b-col>

              <!--  <b-col
                        cols="12"
                        md="4"
                >
                  <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="配货编号:"
                          label-for="mergeNo"
                          label-size="sm"

                  >
                    <b-form-input
                            v-model="state.condition.mergeNo"
                            name="mergeNo"
                            class="d-inline-block mr-1"
                            size="sm"
                    />
                  </b-form-group>
                </b-col>-->

              <!--      退货编号        -->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="退货编号:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.returnbound_no"
                      name="order_no"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <!--是否入库-->
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="是否入库"
                    label-for=""
                    label-size="sm"
                >
                  <v-select
                      id="allot_type"
                      :options="getCodeOptions('yesno')"
                      :clearable="true"
                      v-model="condition.isInboundStr"
                      class="select-size-sm"
                      placeholder="选择是否入库"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>

              <!--终端客户-->
              <b-col md="4" cols="12" class="mb-1"
              >
                <xy-input-button
                    label="终端客户"
                    type="input"
                    v-on:change="fromChildren($event,['channel_name','channel_id'])"
                    :params="['channel_name','channel_id']"
                    :value="condition.channel_name"
                    modalName="终端客户"
                    placeholder="点击选择终端客户"
                >
                </xy-input-button>
              </b-col>

              <!--团队-->
              <b-col
                  cols="12"
                  md="4"
              >
                <xy-input-button
                    label="团队名称"
                    type="input"
                    v-on:change="fromChildren($event,['store_name','store_id'])"
                    :params="['store_name','store_id']"
                    :value="condition.store_name"
                    modalName="团队"
                    placeholder="点击选择团队名称"
                >
                </xy-input-button>
              </b-col>

              <!--      状态         -->
              <!--  <b-col
                        cols="12"
                        md="4"
                >
                  <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label="订单状态:"
                          label-for="orderStatus"
                          label-size="sm"
                  >
                    <v-select
                            id="status"
                            :clearable="true"
                            :options="getCodeOptions('pre_returnbound_status')"
                            v-model="condition.status"
                            name="orderStatus"
                            class="select-size-sm"
                            placeholder="请选择订单状态"
                            :reduce="option=>option.value"
                    />
                  </b-form-group>
                </b-col>-->

              <!--     退货日期         -->
              <b-col md="4" cols="12">
                <xy-input-button label="退货日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['return_time_str'])"
                                 :params="['return_time_str']"
                                 :value="condition.return_time_str"
                >
                </xy-input-button>
              </b-col>


              <!-- 入库仓库 warehouse_id -->
              <b-col
                  cols="12"
                  md="4"
                  class="mb-1"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="入库仓库"
                    label-for="user_level"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      v-model="condition.warehouseArray"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      label="label"
                      :options="getCodeOptions('warehouse')"
                      class="select-size-sm"
                      :close-on-select="false"
                  />
                </b-form-group>
              </b-col>


              <!--     销售员         -->
              <b-col
                  cols="12"
                  md="4"

              >
                <xy-input-button
                    label="销售员"
                    type="input"
                    v-on:change="fromChildren($event,['sales_name','sales_id'])"
                    :params="['sales_name','sales_id']"
                    :value="condition.sales_name"
                    modalName="销售员"
                    placeholder="点击选择销售员"
                >
                </xy-input-button>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
                  class="mb-2"
              >
                <xy-input-button
                    label="创建人"
                    type="input"
                    v-on:change="fromChildren($event,['creatorName','creator'])"
                    :params="['creatorName','creator']"
                    :value="condition.creatorName"
                    modalName="创建人"
                    placeholder="点击选择创建人"
                >
                </xy-input-button>
              </b-col>

              <!--创建日期-->
              <b-col md="4" cols="12">
                <xy-input-button label="创建日期"
                                 type="time"
                                 v-on:change="fromChildren($event,['add_time_str'])"
                                 :params="['add_time_str']"
                                 :value="condition.add_time_str"
                >
                </xy-input-button>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>

              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

    </b-card>

    <b-card style="margin-top: 15px">
      <div>
        <div class="d-flex mb-2">
          <b-form-radio
              v-model="selected"
              value=""
              name="some-radios"
              plain
              class="vs-radio-con"

          >
            <span class="ml-50 mr-1">全部({{ dataTitle.total }})</span>
          </b-form-radio>
          <b-form-radio
              v-model="selected"
              value="0"
              name="some-radios"
              plain
              class="vs-radio-con"
          >
            <span class="ml-50 mr-1">草稿({{ dataTitle.preOrderStatus0 }})</span>
          </b-form-radio>
          <b-form-radio
              v-model="selected"
              name="some-radios"
              value="1"
              plain
              class="vs-radio-con"
          >
            <span class="ml-50 mr-1">待审核({{ dataTitle.preOrderStatus1 }})</span>
          </b-form-radio>
          <b-form-radio
              v-model="selected"
              name="some-radios"
              value="2"
              plain
              class="vs-radio-con"
          >
            <span class="ml-50 mr-1">已审核({{ dataTitle.preOrderStatus2 }})</span>
          </b-form-radio>
          <b-form-radio
              v-model="selected"
              name="some-radios"
              value="4"
              plain
              class="vs-radio-con"
          >
            <span class="ml-50 mr-1">已作废({{ dataTitle.preOrderStatus4 }})</span>
          </b-form-radio>
          <b-form-radio
              v-model="selected"
              name="some-radios"
              value="5"
              plain
              class="vs-radio-con"
          >
            <span class="ml-50 mr-1">已驳回({{ dataTitle.preOrderStatus5 }})</span>
          </b-form-radio>
        </div>
      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="tableArray"
      >

        <template #row-details="row">
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.items" :fields="fields">
                <!--状态未待审核，退货数量可编辑-->
                <template #cell(return_qty)="data" v-if="row.item.status===1">
                  <b-form-input
                      id="return_qty"
                      size="sm"
                      v-model="row.item.ext.items[data.index].return_qty"
                      @blur="updateItem(row.item,data.index,$event)"
                      type="number"
                      style="width:80px"
                  />
                </template>
                <!--待审核状态，退货价格可编辑-->
                <template #cell(return_price)="data" v-if="row.item.status===1">
                  <!--退货价格与指导价波动超出30% 列表价格标红处理-->
                  <b-form-input
                      id="return_price"
                      size="sm"
                      v-model="row.item.ext.items[data.index].return_price"
                      @blur="updateItem(row.item,data.index,$event)"
                      type="number"
                      style="width:80px;background: #fc4d53; color: cornsilk"
                      v-if="row.item.ext.items[data.index].ext.product_avg_price != 0"
                  />
                  <b-form-input
                      id="return_price"
                      size="sm"
                      v-model="row.item.ext.items[data.index].return_price"
                      @blur="updateItem(row.item,data.index,$event)"
                      type="number"
                      style="width:80px"
                      v-else
                  />
                </template>
                <!--不是待审核 不可编辑-->
                <template #cell(return_price)="data" v-else>
                  <div v-if="row.item.ext.items[data.index].ext.product_avg_price != 0"
                       style="background: #fc4d53; color: cornsilk">{{ row.item.ext.items[data.index].return_price }}
                  </div>
                  <div v-else>
                    {{ row.item.ext.items[data.index].return_price }}
                  </div>
                </template>

                <template #cell(return_qty_select)="data">
                  <!--跳新页面-->
                  <a target="_blank" :href="toURLReturnQty(data.item.product_id,row.item.store_id,row.item.channel_id)">
                     <feather-icon icon="SearchIcon" />
                  </a>
                  <!--本页面-->
                 <!-- <b-link
                          :to="{ name: 'apps-presalesreturnbound-return', query: { product_id: data.item.product_id,store_id:row.item.store_id,channel_id:row.item.channel_id } }"
                          class="font-weight-bold d-block text-nowrap"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-link>-->
                </template>

                <!--草稿状态下 显示删除操作-->
                <template #cell(actions)="data" v-if="row.item.status===0||row.item.status===1">
                  <b-dropdown
                          variant="link"
                          no-caret
                  >
                    <template #button-content>
                      <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="align-middle text-body"
                      />
                    </template>
                    <!--操作按钮-->
                    <b-dropdown-item @click="deleteItem(data)">
                      <feather-icon icon="DeleteIcon"/>
                      <span class="align-middle ml-50">删除</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>

              </b-table>
            </div>
          </b-card>
        </template>

        <template #cell(returnbound_no)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="15"
                class="align-middle text-body"
                v-show="data.detailsShowing===true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing===false"
            />
          </b-form-checkbox>
          [#{{ data.item.id }}]{{ data.item.returnbound_no }}
        </template>


        <template v-slot:head(select)="data">
          <b-form-checkbox v-model="allchecked" @change="toggleAll" :indeterminate="indeterminate">
          </b-form-checkbox>
        </template>

        <template v-slot:cell(select)="row">
          <b-form-checkbox @change="checkboxClick(row.item)" v-model="isCheckedObj['index'+row.item.id]">
          </b-form-checkbox>
        </template>

        <!-- Column: Type -->
        <template #cell(is_inbound)="data">
          <feather-icon
              icon="XIcon"
              size="21"
              v-if="data.item.is_inbound==0||data.item.is_inbound==undefined"
          />
          <feather-icon
              icon="CheckIcon"
              size="21"
              v-else
          />
        </template>


        <template #cell(create_returnbound_id)="data">
          <div v-if="data.item.create_returnbound_id>0">
            <a target="_blank" :href="toURL(data.item.create_returnbound_id)">
              NO：{{ data.item.ext.sales_returnbound_order_no }} ID：{{ data.item.create_returnbound_id }}
            </a>
          </div>
        </template>


        <template #cell(status)="data">
          {{ getCodeLabel('pre_returnbound_status', data.item.status) }}
        </template>

        <template #cell(attachments)="data">
          <div>
            <feather-icon icon="EditIcon" @click="showReceiptAttachments(data.item)"/>
            <attachment-upload :theme="'preview-dropdown'"
                               :attachment_id="'attachments'"
                               :id="data.item.attachments"
                               :object_type="'attachments'"
                               :object_id="0"
                               :readonly="true"
                               @change=""

            />
          </div>
        </template>

        <template #cell(add_time)="data">
          {{ toDate(data.item.add_time) }}
        </template>

        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>

        <template #cell(modify_time)="data">
          {{ toDate(data.item.modify_time) }}
        </template>

        <template #cell(return_time)="data">
          {{ toDate(data.item.return_time) }}
        </template>

        <template #cell(updator)="data">
          {{ getCodeLabel('user', data.item.updator) }}
        </template>

        <template #cell(sales_id)="data">
          {{ getCodeLabel('user', data.item.sales_id) }}
        </template>


        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <!--操作按钮-->
            <div>
                  <span v-if="data.item.status === 0 || data.item.status === 5">
                         <b-dropdown-item
                             :to="{ name: 'apps-presalesreturnbound-edit', query: { id: data.item.id },params:{storeId: data.item.store_id, channelId: data.item.channel_id} }"
                         >
                  <feather-icon icon="EditIcon"/>
                  <span class="align-middle ml-50">编辑</span>
                </b-dropdown-item>
                   </span>

              <span v-if="data.item.status === 0 || data.item.status === 5">
                         <b-dropdown-item @click="changeStatus(data.item,1,'提交审核')">
                            <feather-icon icon="FileTextIcon"/>
                            <span class="align-middle ml-50">提交</span>
                         </b-dropdown-item>
                   </span>

              <span v-if="data.item.status === 0 || data.item.status === 5">
                         <b-dropdown-item @click="changeStatus(data.item,4,'作废')">
                            <feather-icon icon="FileTextIcon"/>
                            <span class="align-middle ml-50">作废</span>
                         </b-dropdown-item>
                   </span>

              <span v-if="data.item.status === 1 ">
                         <b-dropdown-item @click="changeStatus(data.item,'','同意')">
                            <feather-icon icon="FileTextIcon"/>
                            <span class="align-middle ml-50">同意</span>
                         </b-dropdown-item>

                       <b-dropdown-item @click="changeStatus(data.item,'5','驳回')">
                            <feather-icon icon="FileTextIcon"/>
                            <span class="align-middle ml-50">驳回</span>
                         </b-dropdown-item>

                   </span>

              <span v-if="data.item.status === 2 ">
                         <b-dropdown-item @click="changeStatus(data.item,5,'撤销审核')">
                            <feather-icon icon="FileTextIcon"/>
                            <span class="align-middle ml-50">撤销审核</span>
                         </b-dropdown-item>
                   </span>


            </div>


          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>

        <!-- 抬头弹出窗口 -->
        <b-modal
            id="warehouseHeadModal"
            ok-only
            ok-title="确认"
            cancel-title="取消"
            centered
            size="sm"
            title="选择抬头"
            ref="warehouseHeadModal"
            @ok="toLODOP"
            @close="cleanWarehouseHeadArray"
            no-close-on-backdrop
            no-close-on-esc
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="抬头"
              label-for="label"
              label-size="sm"
              class="mb-1"
          >
            <v-select
                :options="warehouseHeadArray"
                :clearable="true"
                v-model="warehouseHead"
                @input="setWarehouseHead($event)"
                class="select-size-sm"
                placeholder="请选择抬头"
            />
          </b-form-group>
        </b-modal>
      </div>

      <b-modal
          id="modal-attachments-receipt"
          centered
          ok-only
          ok-title="确认"
          size="lg"
          title="添加附件"
          ref="remarkModal"
          @ok="saveAttachments"
          @hidden="clearInput"
      >


        <b-row>

          <b-col cols="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="附件"
                label-for="attachments_ids"
                label-size="sm"
                class="mb-1"
            >
              <attachment-upload :theme="'files'"
                                 :attachment_id="'attachments_ch'"
                                 :id="attachmentsStr"
                                 :object_type="'allot_outbound'"
                                 :object_id="attachmentsObjectIdCh"
                                 @change="onUploadedExcel"
              />
            </b-form-group>
          </b-col>


        </b-row>

      </b-modal>

      <!--导入错误信息显示-->
      <b-modal
          id="exportErrorModalId"
          centered
          hide-footer
          size="lg"
          ref="exportErrorModalId"
          title="导入错误信息"
      >

        <b-row>

          <b-col cols="12">
            <b-form-textarea
                id="textarea-default"
                rows="10"
                :value="exportErrorMsg"
            />
          </b-col>


        </b-row>

      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BNav, BNavItem, BFormRadio
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  lodopWatermark, isEmpty, isNumber
} from '@core/utils/filter'
import presalesreturnboundUseList from './presalesreturnboundUseList'
import presalesreturnboundStore from './presalesreturnboundStore'
import {ref, onUnmounted, reactive, onMounted, toRefs} from '@vue/composition-api'
import XyInputButton from "@/views/components/xy/XyInputButton";
import Ripple from "vue-ripple-directive";
import {useToast} from "vue-toastification/composition";
import lodopStore from "@/views/apps/lodop/lodopStore";
import {getLodop} from "@/libs/LodopFuncs";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import {getUserData} from "@/auth/utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    XyInputButton,
    BNav,
    BNavItem,
    BFormRadio,
    AttachmentUpload
  },
  data() {
    return {
      advanced_search_modal: false,
      allchecked: false,
      indeterminate: false,
      isCheckedObj: {},
      checked: [],
      fields: [
        {key: 'code', label: '69码'},
        {key: 'name', label: '商品名称'},
        {key: 'specification', label: '商品规格'},
        {key: 'return_price', label: '退货价'},
        {key: 'return_qty', label: '计划退货数量'},
        {key: 'return_qty_select', label: '退货查询'},
        //{ key: 'sales_price', label: '单价'},
        {key: 'subtotal', label: '小计'},
        {key: 'true_qty', label: '实际退货数量'},
        {key: 'memo', label: '备注'},
        {key: 'actions', label: '操作'}
      ],
      tableArray: [],
      warehouseHeadArray: [],
      warehouseHead: '',
      attachmentsStr: "",
      orderId: "",
      attachmentsId: "",
      attachmentsObjectIdCh: '',
      count: 0,
      readonly: 1,
      advanced_search_modal_excel: false,
      excelSqlId: '',
      exportErrorMsg: '',
      exportErrorModal: false,
      showOr: true,
      readonlyExcel: false,
    }
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('presalesreturnbound/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    selectByStatus(status) {
      alert("该功能暂未开放aaa！" + status)
    },
    clearInput: function () {
      this.attachmentsStr = ''
      this.attachmentsObjectIdCh = ''
      this.$refs['refListTable'].refresh()
    },
    searchByCondition() {
      this.refetchData()
    },
    resetCondition() {
      store.commit('presalesreturnbound/resetCondition')
      this.refetchData();
    },
    // 点击全选按钮
    toggleAll(checked) {
      const array = []
      for (let i = 0; i < this.checked.length; i++) {
        for (let j = 0; j < this.tableArray.length; j++) {
          if (this.checked.length > 0 && this.checked[i].entity_id == this.tableArray[j].entity_id) {
            array.push(i)
          }
        }
      }
      for (let i = array.length - 1; i >= 0; i--) {
        this.checked.splice(array[i], 1);
      }
      this.tableArray.forEach(element => {
        if (checked) {
          this.checked.push(element);
        }
        this.isCheckedObj['index' + element.id] = checked;
      });
      this.$forceUpdate()

    },
    // 复选框点击选中与取消
    checkboxClick(id) {
      let index = -1;
      for (let i = 0; i < this.checked.length; i++) {
        if (this.checked[i].entity_id == id.entity_id) {
          index = i
        }
      }
      if (index > -1) {
        this.checked.splice(index, 1);
      } else {
        this.checked.push(id);
      }
      this.$forceUpdate()
    },
  },
  mounted() {
    this.isCheckedObj = {};
    this.tableArray.forEach(element => {
      this.$set(this.isCheckedObj, 'index' + element.id, false)
    });
  },
  watch: {
    checked: {
      handler(newVal, oldVal) {
        let show = 0;
        for (let i = 0; i < this.checked.length; i++) {
          for (let j = 0; j < this.tableArray.length; j++) {
            if (this.checked[i].entity_id == this.tableArray[j].entity_id) {
              show += 1;
            }
          }
        }
        if (newVal.length == 0) {
          this.indeterminate = false
          this.allchecked = false
        } else if (this.tableArray.length === show) {
          this.indeterminate = false
          this.allchecked = true
        } else if (show === 0) {
          this.indeterminate = false
          this.allchecked = false
        } else {
          this.indeterminate = true
          this.allchecked = false
        }
        this.$forceUpdate()
      },
      deep: true//深度
    },
    tableArray: {
      handler(newVal, oldVal) {
        let show = 0;
        for (let i = 0; i < this.checked.length; i++) {
          for (let j = 0; j < this.tableArray.length; j++) {
            if (this.checked[i].entity_id == this.tableArray[j].entity_id) {
              show += 1;
            }
          }
        }
        if (this.tableArray.length === show) {
          this.indeterminate = false
          this.allchecked = true
        } else if (show === 0) {
          this.indeterminate = false
          this.allchecked = false
        } else {
          this.indeterminate = true
          this.allchecked = false
        }
      }
    },
    selected: {
      handler() {
        this.checked = []
        this.tableArray = []
        this.allchecked = false
        this.indeterminate = false
        this.isCheckedObj = {}
        this.refetchData()
      }
    }
  },
  directives: {
    Ripple,
  },
  setup() {
    // Register module
    if (!store.hasModule('presalesreturnbound')) store.registerModule('presalesreturnbound', presalesreturnboundStore)
    if (!store.hasModule('lodop')) store.registerModule('lodop', lodopStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('presalesreturnbound')) store.unregisterModule('presalesreturnbound')
      if (store.hasModule('lodop')) store.unregisterModule('lodop')
    })

    onMounted(() => {
      state.condition = store.getters['presalesreturnbound/getCondition']
    })
    const toast = useToast()

    const state = reactive({
      condition: {}
    })

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        state.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const confirmSubmit = function (id, status) {
      store.dispatch('presalesreturnbound/changeStatus', {id: id, status: status}).then(res => {
        if (res.data.code === 0) {
          toast.success("操作成功")
          this.refetchData()
        } else {

          let data = JSON.parse(res.data.data)
          if (isEmpty(data[0].product_name) && isEmpty(data[0].product_id)) {
            toast.error(data[0].error)
          } else {
            let result = []
            for (let i in data) {
              let return_qty = data[i].return_qty
              let can_return_qty = data[i].can_return_qty
              let product_id = data[i].product_id
              let product_name = data[i].product_name
              let error = "ID:" + product_id + ",商品名称:" + product_name + ",本次退货数量:" + return_qty + ",可退数量:" + can_return_qty
              result.push(error);
            }
            toast.error(JSON.stringify(result))
          }
          this.refetchData()
        }
      })
    }
    const changeStatus = function (params, toStatus, information) {
      if (information === '提交审核') {
        let isOver = 0;
        for (let i = 0; i < params.ext.items.length; i++) {
          if (params.ext.items[i].ext.product_avg_price != 0) {
            isOver = isOver + 1;
          }
        }
        if (isOver > 0) {
          if (confirm('警告！标红商品价格可能有误，是否继续提交？')) {
            this.confirmSubmit(params.returnbound_id, toStatus);
          }
        } else {
          this.confirmSubmit(params.returnbound_id, toStatus);
        }
      }
      if (information === '作废' || information === '驳回') {
        store.dispatch('presalesreturnbound/changeStatus', {id: params.returnbound_id, status: toStatus}).then(res => {
          if (res.data.code === 0) {
            toast.success("操作成功")
            this.refetchData()
          } else {
            toast.error(res.data.data)
            this.refetchData()
          }
        })
      }

      if (information === '同意') {
        if(isNumber(params.item_count)>0){
          store.dispatch('presalesreturnbound/checkSuccess', {id: params.returnbound_id}).then(res => {
            if (res.data.code === 0) {
              toast.success("操作成功")
              this.refetchData()
            } else {
              toast.error(res.data.data)
              this.refetchData()
            }
          })
        }else{
          toast.error("无退货明细,审核失败!")
          return false
        }

      }

      if (information === '撤销审核') {
        store.dispatch('presalesreturnbound/cancelAudit', {id: params.returnbound_id}).then(res => {
          if (res.data.code === 0) {
            toast.success("操作成功")
            this.refetchData()
          } else {
            toast.error(res.data.data)
            this.refetchData()
          }
        })
      }

    }

    const toLODOP = function () {
      if (this.checked.length == 0) {
        toast.error("请选择前置退货单!")
        return false
      }
      const idArray = []
      const setWarehouse = new Set()
      const warehouseArray = []
      for (let i = 0; i < this.checked.length; i++) {
        idArray.push(this.checked[i].returnbound_id)
        setWarehouse.add(this.checked[i].warehouse_id)
        warehouseArray.push(this.checked[i].warehouse_id)
        // if (this.checked[i].status != 2) {
        //   toast.error("只有审核通过状态才能打印!")
        //   return false
        // }
      }
      if (setWarehouse.size != 1 && isEmpty(this.warehouseHead)) {
        if (confirm("所选订货单仓库不一致，是否继续")) {
          var promise = Promise.resolve()
          for (let i = 0; i < this.checked.length; i++) {
            promise = promise.then(() => {
              return print(this.checked[i], this, warehouseArray.join(","))
            })
          }
          promise.then(() => {
            this.reload()
            this.cleanWarehouseHeadArray()
          })
        }
      } else {
        var promise = Promise.resolve()
        for (let i = 0; i < this.checked.length; i++) {
          promise = promise.then(() => {
            return print(this.checked[i], this, warehouseArray.join(","))
          })
        }
        promise.then(() => {
          this.reload()
          this.cleanWarehouseHeadArray()
        })
      }

    }

    const updateItem = function (item, index, event) {
      let err = 0
      const cRegExp = /^[1-9]\d*$/
      if (!cRegExp.test(item.ext.items[index].return_qty)) {
        toast.error('售卖数量必须是正整数!')
        err = 1
      }
      if (isNumber(item.ext.items[index].return_price) < 0) {
        toast.error("价格不能为负数")
        err = 1
      }
      if (err !== 0) {
        return false
      }
      store.dispatch('presalesreturnbound/updateItem', {
        returnboundId: item.returnbound_id,
        itemId: item.ext.items[index].item_id,
        returnQty: item.ext.items[index].return_qty,
        returnPrice: item.ext.items[index].return_price,
      }).then(res => {
        if (res.data.code !== 0) {
          toast.error('更新数量/价格失败')
        }
      })
    }

    const print = function (printobject, _this, warehouseArray) {
      return new Promise((resolve, reject) => {
        store.dispatch('lodop/createPreSalesReturnBoundLODOP', {
          ids: printobject.returnbound_id,
          count: _this.count,
          warehouseIds: warehouseArray
        }).then(res => {
          if (res.data.code === 0) {
            const data = res.data.data
            _this.count = 0
            var LODOP = getLodop();
            if (LODOP == undefined) {
              toast.error("打印插件未安装，请安装插件!")
              return;
            }
            LODOP.PRINT_INIT('打印送货单') //打印初始化
            //LODOP.SET_PRINTER_INDEX(printer) //选择的打印机类型（注意这里是打印机下标，0开始的）
            LODOP.SET_PRINT_STYLE('FontSize', 18) //设置对象风格
            // this.LODOP.ADD_PRINT_TEXT(50, 521, 130, 39, this.description) //增加纯文本项
            if (data[0].ext.page == 2) {
              LODOP.SET_PRINT_PAGESIZE(1, 2200, 1400, '') //设定纸张大小
            }
            if (data[0].ext.page == 3) {
              LODOP.SET_PRINT_PAGESIZE(1, 2200, 930, '') //设定纸张大小
            }
            LODOP.SET_PREVIEW_WINDOW(2, 2, 0, 0, 0, '')//设置窗口
            var html = ''
            for (let i = 0; i < data.length; i++) {
              if (data[i].ext.warehouseHeadList.length > 1 && isEmpty(_this.warehouseHead)) {
                _this.count += 1
                _this.warehouseHeadArray.length = 0
                const warehouseHeadList = data[i].ext.warehouseHeadList
                for (let j = 0; j < warehouseHeadList.length; j++) {
                  _this.warehouseHeadArray.push({
                    "label": warehouseHeadList[j].head_info,
                    "head_id": warehouseHeadList[j].head_id
                  })
                }
                _this.$refs['warehouseHeadModal'].show()
                return;
              }
              var item = ``
              var items = data[i].ext.item
              for (let j = 0; j < items.length; j++) {
                item += ` <tr height="23px">
                        <td align="center">${items[j].ext.no}</td>
                        <td align="center">${items[j].ext.code}</td>
                        <td align="left">${(items[j].name.length > 28) ? items[j].name.substring(0, 28) : items[j].name}</td>
<!--                        <td align="center">${items[j].ext.specification}</td>-->
                        <td align="center">${items[j].ext.product_unit}</td>
                        <td align="center">-${items[j].return_qty}</td>
                        <td align="right">${items[j].return_price.toFixed(2)}</td>
                        <td align="right">-${items[j].ext.subtotal}</td>
                        <td align="center">${items[j].memo == null ? '' : items[j].memo}</td>
                    </tr>`
              }
              html = `
                <div style="width:780px">
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr height="30px">
                        <td colspan="2">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr height="30px">
                                    <td width="30%">${data[i].ext.time} (第${data[i].ext.printTime}次) ${data[i].returnbound_no}</td>
                                    <td width="40%" align="center">&nbsp;</td>
                                    <td width="30%" align="right">&nbsp;</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr height="20px">
                        <td colspan="2" valign="top">
                            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                <tr height="20px">
                                    <td width="186" align="center" valign="bottom"></td>
                                    <td width="414" align="center"><font face="楷体" size="4" style="font-weight: bold">${data[i].ext.warehouseHeadList.length == 0 ? '' : (data[i].ext.warehouseHeadList.length == 1 ? data[i].ext.warehouseHeadList[0].head_info : _this.warehouseHead)}退货单</font></td>
                                    <td width="180">&nbsp;</td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr height="20px">
                        <td width="50%">客户：${data[i].ext.nickName}</td>
                        <td width="50%">退货人：${data[i].ext.contactName}${data[i].ext.contactMobile}</td>
                    </tr>
                    <tr height="20px">
                        <td width="50%">地址：${data[i].ext.address == null ? '' : data[i].ext.address}</td>
                        <td width="50%">备注：${data[i].memo == undefined ? "" : data[i].memo}</td>
                    </tr>
                </table>
                <table width="100%" class="protable">
                    <tr height="25px">
                        <td width="4%" align="center">序号</td>
                        <td width="12%" align="center">69码</td>
                        <td align="center">商品名称</td>
<!--                        <td width="8%" align="center">规格</td>-->
                        <td width="5%" align="center">单位</td>
                        <td width="6%" align="center">数量</td>
                        <td width="7%" align="center">单价</td>
                        <td width="10%" align="center">金额</td>
                        <td width="8%" align="center">备注</td>
                    </tr>
                    ${item}
                    <tr height="23px">
                        <td align="center"></td>
                        <td align="center">合计</td>
                        <td align="center">${data[i].ext.allPriceString}</td>
<!--                        <td align="center"></td>-->
                        <td align="center"></td>
                        <td align="center">-${data[i].ext.allQtySheet}</td>
                        <td align="center"></td>
                        <td align="center">¥-${data[i].ext.subtotal}</td>
                        <td align="center"></td>
                    </tr>
                </table>
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr height="20px">
                        <td colspan="3">
                          <table width="100%" border="0" cellspacing="0" cellpadding="0">
                              <tr height="25px">
                                <td width="50%">卖方仓库：${data[i].ext.warehouse.location}</td>
                                <td width="25%">监督电话：${data[i].ext.warehouse.mobile}</td>
                                <td width="25%">业务员：${data[i].ext.sales}</td>
                              </tr>
                          </table>
                        </td>
                    </tr>
                    <tr height="20px">
                        <td>制单人：${data[i].ext.user}</td>
                        <td>车型： </td>
                        <td>车牌号码： </td>
                        <td>司机签字：</td>
                    </tr>
                    <tr height="50px">
                        <td>发货人签字：</td>
                        <td>审核人签字：</td>
                        <td>收货人签字：</td>
                    </tr>
                </table>
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tr height="25px">
                        <td align="center"><font size="2px" style="font-weight: bold">**友情提醒：货物请当面点清，本退货单如购销双方未签订合同，此退货单视为合同**</font></td>
                    </tr>
                    <tr height="25px">
                        <td align="center"><font size="2px">**白联：存根|红联：客户|蓝联：财务|绿联：司机|黄联：仓管**</font></td>
                    </tr>
                </table>
                </div>
          `
              lodopWatermark(LODOP)
              // LODOP.ADD_PRINT_BARCODE(30, 10, 186, 36, "128Auto", data[i].returnbound_no);
              // LODOP.SET_PRINT_STYLEA(0, "ShowBarText", 0);//设置是否显示下方的文字
              //二维码 Y轴 X轴
              // const qrpath = data[i].ext.qrurl
              // LODOP.ADD_PRINT_IMAGE(25, 682, 100, 100, `<img border="0" src="${qrpath}" width="100" height="100">` + `</img`);
              // LODOP.SET_PRINT_STYLEA(0, "Stretch", 2);//(可变形)扩展缩放模式
              var style =
                  "<style>" +
                  "table{}" +
                  "table td{font-size: 14px;white-space: nowrap;}" +
                  ".protable{border-collapse: collapse;border: none;}" +
                  ".protable td{border: solid #000 1px;}" +
                  "</style>";
              LODOP.ADD_PRINT_HTM(0, 0, 1500, 1500, style + '<body size="10px">' + html + '</body>') // 增加超文本项（打印内容即为body内容）
              LODOP.NewPage();
            }
            // LODOP.PREVIEW()
            LODOP.PRINT() // 设置预览（如果使用预览，每次打印前会弹出预览窗口，根据需要决定是否使用）
            resolve()
          } else {
            toast.error(res.data.data)
          }
        })
      })
    }


    const distribution = function () {
      if (this.checked.length == 0) {
        toast.error("请选择前置退货!")
        return false
      }
      const idArray = []
      const setWarehouse = new Set()
      for (let i = 0; i < this.checked.length; i++) {
        idArray.push(this.checked[i].returnbound_id)
        setWarehouse.add(this.checked[i].warehouse_id)
        if (this.checked[i].status != 2) {
          toast.error("只有审核通过状态才能打印!")
          return false
        }
      }
      if (setWarehouse.size != 1) {
        toast.error("请选择相同仓库的前置销售单!")
        return false
      }
      const ids = idArray.join(",")
      store.dispatch('lodop/createPreSalesReturnboundDistributionLODOP', {
        ids: ids,
      }).then(res => {
        if (res.data.code === 0) {
          var LODOP = getLodop();
          if (LODOP == undefined) {
            toast.error("打印插件未安装，请安装插件!")
            return;
          }
          LODOP.PRINT_INIT('打印配货单') //打印初始化
          //LODOP.SET_PRINTER_INDEX(printer) //选择的打印机类型（注意这里是打印机下标，0开始的）
          LODOP.SET_PRINT_STYLE('FontSize', 18) //设置对象风格
          // this.LODOP.ADD_PRINT_TEXT(50, 521, 130, 39, this.description) //增加纯文本项
          LODOP.SET_PRINT_PAGESIZE(1, 0, 0, 'A4') //设定纸张大小
          // LODOP.SET_PRINT_MODE('PRINT_PAGE_PERCENT', '55%')//设置缩放
          LODOP.SET_PREVIEW_WINDOW(2, 2, 0, 0, 0, '')//设置窗口

          const data = res.data.data.ext
          var item = ``
          for (let i = 0; i < data.item.length; i++) {
            item += `<tr height="23px">
                        <td align="center">${data.item[i].ext.no}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${data.item[i].ext.code}</td>
                        <td align="left" style="white-space: nowrap;">${data.item[i].name}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${data.item[i].ext.specification}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${data.item[i].ext.productUnit}</td>
                        <td align="center" style="white-space: nowrap;">${data.item[i].return_qty}</td>
                        <td align="center" style="white-space: nowrap;">${data.item[i].true_qty == undefined ? 0 : data.item[i].true_qty}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;"></td>
                        </tr>`
          }

          var html = `
                <table width="740px" border="0" cellspacing="0" cellpadding="0">
                    <tr height="20px">
                        <td colspan="3">&nbsp;</td>
                    </tr>
                    <tr height="30px">
                        <td colspan="3" align="center"><font face="楷体" size="4">配货单</font></td>
                    </tr>
                    <tr height="25px">
                        <td width="370">仓库名称：${data.warehouseName}</td>
                        <td width="370"></td>
                    </tr>
                    <tr height="25px">
                        <td width="370">制单人：${data.user}</td>
                        <td width="370">打印时间：${data.time}</td>
                    </tr>
                </table>

                <table width="740px" border="1" cellspacing="0" cellpadding="0">
                    <tr height="25px">
                        <td width="40px" align="center">序号</td>
                        <td width="100px" align="center">69码</td>
                        <td width="260px" align="center">商品名称</td>
                        <td width="50px" align="center">规格</td>
                        <td width="50px" align="center">单位</td>
                        <td width="80px" align="center">计划数量</td>
                        <td width="80px" align="center">实际数量</td>
                        <td width="60px" align="center">备注</td>
                    </tr>
                    ${item}
                    <tr height="23px">
                        <td align="center"></td>
                        <td align="left" style="font-size:12px;white-space: nowrap;"></td>
                        <td align="center" style="font-size:12px;white-space: nowrap;"></td>
                        <td align="center" style="font-size:12px;white-space: nowrap;"></td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">合计</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${data.allQty}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;">${data.trueQty}</td>
                        <td align="center" style="font-size:12px;white-space: nowrap;"></td>
                    </tr>
                </table>
                    <table width="740px" border="0" cellspacing="0" cellpadding="0">
                    <tr height="50px">
                        <td width="246">仓管签字：</td>
                        <td width="246">审核签字：</td>
                        <td width="246">司机签字：</td>
                    </tr>
                    <tr height="25px">
                        <td colspan="3" align="center"><font face="楷体" size="2" style="font-weight: bold">**友情提醒：货物请当面点清，事后恕不负责**</font></td>
                    </tr>
                </table>
          `
          lodopWatermark(LODOP)
          LODOP.ADD_PRINT_HTM(0, 0, 1500, 1500, '<body>' + html + '</body>') // 增加超文本项（打印内容即为body内容）
          LODOP.SET_PRINT_MODE("DOUBLE_SIDED_PRINT", 0);
          LODOP.PREVIEW() // 设置预览（如果使用预览，每次打印前会弹出预览窗口，根据需要决定是否使用）
          // LODOP.PRINT()
        } else {
          let data = JSON.parse(res.data.data)
          if (isEmpty(data[0].product_name) && isEmpty(data[0].product_id)) {
            toast.error(data)
          } else {
            let result = []
            for (let i in data) {
              let return_qty = data[i].return_qty
              let can_return_qty = data[i].can_return_qty
              let product_id = data[i].product_id
              let product_name = data[i].product_name
              let error = "ID:" + product_id + ",商品名称:" + product_name + ",本次退货数量:" + return_qty + ",可退数量:" + can_return_qty
              result.push(error);
            }
            toast.error(JSON.stringify(result))
          }
        }
      })
      this.reload()
      this.cleanWarehouseHeadArray()
    }

    const setWarehouseHead = function (event) {
      if (event != null) {
        this.warehouseHead = event.label
      } else {
        this.warehouseHead = null
      }
      this.$forceUpdate()
    }

    const cleanWarehouseHeadArray = function () {
      this.warehouseHeadArray.length = 0
      this.warehouseHead = ""
    }

    const reload = function () {
      this.checked.forEach(element => {
        this.isCheckedObj['index' + element.id] = false;
      });
      this.checked.length = 0
      this.indeterminate = false
      this.allchecked = false
      refetchData()
    }

    const showReceiptAttachments = function (item) {
      this.orderId = item.returnbound_id
      this.attachmentsObjectIdCh = item.returnbound_id
      this.attachmentsStr = item.attachments
      this.$refs['remarkModal'].show()
    }

    const onUploadedExcel = function (id, attachment, result) {
      this.attachmentsId = result
    }

    const onUploaded = function (id, attachment, result) {
      this.excelSqlId = result
    }
    const saveAttachments = function () {
      store.dispatch('presalesreturnbound/addAttachments', {
        id: this.orderId,
        attachmentsId: this.attachmentsId
      }).then(res => {
        if (res.data.code === 0) {
          toast.success("添加成功")
        } else {
          toast.error("添加失败")
        }
      })
    }
    const toURL = function (urlId) {
      //售后退货通知单
      return "/apps/salesreturnbound/warehouseList?returnbound_id=" + urlId

    }
    const toURLReturnQty = function (product_id,store_id,channel_id) {
      //退货查询
      return "/apps/presalesreturnbound/return?product_id=" + product_id+"&store_id="+store_id+"&channel_id="+channel_id

    }

    const exportData = function () {
      let that = this
      that.showOr = false
      const params = {
        excelSqlId: this.excelSqlId,
      }
      store.dispatch('presalesreturnbound/batchReturnBound', params)
          .then(response => {
            const data = response.data.data
            if (response.data.code == 0) {
              toast.success("导入成功")
              refetchData();
            } else {
              that.$refs['exportErrorModalId'].show()
              that.exportErrorMsg = data
            }
          })
    }

    const exportReturnData = function () {
      const condition = state.condition
      condition.status = selected.value
      const params = {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc' : '',
      }
      Object.assign(params, condition)
      const warehouseArray = []
      if (!isEmpty(condition.warehouseArray)) {
        condition.warehouseArray.forEach((value, index, array) => {
          warehouseArray.push(value.value)
        })
      }
      params.warehouseIds = warehouseArray.join(",")
      store.dispatch('presalesreturnbound/exportPreSalesReturnboundData', params).then(res => {
        toast.success("导出成功")
        let data = new Blob([res.data], {type: 'application/vnd.ms-excel'})   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = "销售退货" + new Date().getTime();
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data);
        link.setAttribute('download', filename + '.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })

    }

    const advanced_search_excel = function () {
      this.advanced_search_modal_excel = this.advanced_search_modal_excel ? false : true;
    }

    const deleteItem = function (data) {
      if (confirm('确认删除退货单明细？')) {
        store.dispatch('presalesreturnbound/delItem', {
          itemId: data.item.item_id,
          returnboundId: data.item.returnbound_id,
        }).then(response => {
          if (response.data.code === 1) {
            toast.error(response.data.data)
          }
          if (response.data.code === 0) {
            //改状态
            toast.success('删除成功')
          }
          refetchData()
        })
      }

    }



    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      selected,
      dataTitle

      // UI
    } = presalesreturnboundUseList()

    return {
      ...toRefs(state),
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      selected,
      dataTitle,
      refetchData,
      updateItem,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      advanced_search,
      fromChildren,
      toLODOP,
      changeStatus,
      setWarehouseHead,
      cleanWarehouseHeadArray,
      reload,
      showReceiptAttachments,
      onUploadedExcel,
      saveAttachments,
      toURL,toURLReturnQty,
      distribution,
      exportData,
      onUploaded,
      advanced_search_excel,
      exportReturnData,
      confirmSubmit,
      isEmpty,deleteItem

    }
  },
  created() {
    const userData = getUserData()
    if (userData.role_id == 14 || userData.role_id == 17 || userData.role_id == 18) {
      this.readonlyExcel = true
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
